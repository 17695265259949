<template>
  <b-modal id="viewLicense" centered size="md" hide-footer no-close-on-backdrop>
    <template #modal-header>
      <div class="addLicense-header text-center">
        <h4>License Detail</h4>
        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times" />
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div class="form-group row">
        <div class="col-xxl-6 col-lg-6 col-md-6 mb-3">
          <label>State:</label>
          <p>{{ row.state }}</p>
        </div>
        <div class="col-xxl-6 col-lg-6 col-md-6 mb-3">
          <label>License#:</label>
          <p>{{ row.license_number }}</p>
        </div>
        <div class="col-xxl-6 col-lg-6 col-md-6 mb-3">
          <label>Valid Until#:</label>
          <p>{{ row.license_exp_date }}</p>
        </div>
        <div class="col-xxl-6 col-lg-6 col-md-6 mb-3">
          <label>Status:</label>
          <p :class="`id-status-${row.status}`">
            {{ row.status }}
          </p>
        </div>
        <div class="col-xxl-6 col-lg-6 col-md-6 mb-3">
          <label>Resident:</label>
          <p>{{ row.license_resident ? "Yes" : "No" }}</p>
        </div>
        <div class="clearfix"></div>
        <div class="col-xxl-6 col-lg-6 col-md-6 mb-3" v-if="row.license_doc">
          <label>License File:</label>
          <p v-if="fileType == 'pdf'">
            <a :href="row.license_doc" target="_blank" download>
              <i class="fas fa-file-pdf"></i>
            </a>
          </p>
          <p v-else-if="fileType == 'image'">
            <a :href="row.license_doc" target="_blank" download>
              <img
                :src="row.license_doc"
                class="license-doc"
                :alt="row.license_id"
              />
            </a>
          </p>
        </div>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
export default {
  name: "LicenseView",
  props: {
    row: {
      required: true,
    },
  },
  computed: {
    fileType() {
      let [filename, ext] = [this.row.license_doc, ""];
      if (filename) {
        ext = filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
      }
      if (ext == "pdf") {
        return "pdf";
      } else if (ext == "png" || ext == "jpeg" || ext == "jpg") {
        return "image";
      } else {
        return "";
      }
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("viewLicense");
      this.$emit("onCloseModal");
    },
  },
};
</script>
<style scoped>
.license-doc {
  height: 100px;
  width: 100px;
}
</style>