var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('simple-table', {
    attrs: {
      "columns": _vm.columns
    },
    scopedSlots: _vm._u([{
      key: "tableBody",
      fn: function fn() {
        return [_c('tbody', [!_vm.rows.records ? _c('tr', [_c('td', {
          attrs: {
            "colspan": 6,
            "align": "center"
          }
        }, [_vm._v("No records found.")])]) : _vm._e(), _vm._l(_vm.rows.records, function (row, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(row.state))]), _c('td', [_vm._v(_vm._s(row.license_number))]), !_vm.$route.params.downlineRepId ? _c('td', [_vm._v(" " + _vm._s(row.selfLicense ? "My Self" : row.rep_name) + " ")]) : _vm._e(), _c('td', [_vm._v(_vm._s(row.license_exp_date))]), _c('td', [_c('span', {
            class: "id-status-".concat(row.status.toLowerCase())
          }, [_vm._v(" " + _vm._s(row.status) + " ")])]), _c('td', [_vm._v(" " + _vm._s(row.license_resident ? "Yes" : "No") + " ")]), _c('td', [_c('div', {
            staticClass: "action-icons"
          }, [_c('custom-button', {
            attrs: {
              "title": "View Detail",
              "iconClass": "fas fa-eye"
            },
            nativeOn: {
              "click": function click($event) {
                return _vm.viewDetail(row);
              }
            }
          }), _c('custom-button', {
            attrs: {
              "title": "Delete",
              "iconClass": "fas fa-trash-alt"
            },
            nativeOn: {
              "click": function click($event) {
                return _vm.deleteRow(row.license_id);
              }
            }
          })], 1)])]);
        })], 2)];
      },
      proxy: true
    }])
  }), _c('license-view', {
    attrs: {
      "row": _vm.row
    },
    on: {
      "update:row": function updateRow($event) {
        _vm.row = $event;
      },
      "onCloseModal": function onCloseModal($event) {
        _vm.row = {};
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }