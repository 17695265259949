var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper license-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("Licenses")]), _c('custom-button', {
    attrs: {
      "title": "Add License",
      "iconClass": "fas fa-plus",
      "className": "add-btn",
      "haveText": ""
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.openAddLicenseModal.apply(null, arguments);
      }
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('license-filter', {
    attrs: {
      "filters": _vm.filters
    },
    on: {
      "onSearch": _vm.searchHandler,
      "onClearSearch": _vm.clearSearchHandler
    }
  }), _c('div', {
    staticClass: "license-card"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center top-pagination"
  }, [_c('page-result', {
    attrs: {
      "rows": _vm.rows
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  }), _c('pagination', {
    attrs: {
      "rows": _vm.rows,
      "filters": _vm.filters
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  })], 1), _c('license-list-table', {
    attrs: {
      "rows": _vm.rows
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      },
      "onSuccess": _vm.getAll
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('page-result', {
    attrs: {
      "rows": _vm.rows
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  }), _c('pagination', {
    attrs: {
      "rows": _vm.rows,
      "filters": _vm.filters
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  })], 1)], 1)], 1)])], 1), _c('lisense-add', {
    on: {
      "onSuccess": _vm.getAll
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }