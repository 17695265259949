var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "viewLicense",
      "centered": "",
      "size": "md",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "addLicense-header text-center"
        }, [_c('h4', [_vm._v("License Detail")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-xxl-6 col-lg-6 col-md-6 mb-3"
  }, [_c('label', [_vm._v("State:")]), _c('p', [_vm._v(_vm._s(_vm.row.state))])]), _c('div', {
    staticClass: "col-xxl-6 col-lg-6 col-md-6 mb-3"
  }, [_c('label', [_vm._v("License#:")]), _c('p', [_vm._v(_vm._s(_vm.row.license_number))])]), _c('div', {
    staticClass: "col-xxl-6 col-lg-6 col-md-6 mb-3"
  }, [_c('label', [_vm._v("Valid Until#:")]), _c('p', [_vm._v(_vm._s(_vm.row.license_exp_date))])]), _c('div', {
    staticClass: "col-xxl-6 col-lg-6 col-md-6 mb-3"
  }, [_c('label', [_vm._v("Status:")]), _c('p', {
    class: "id-status-".concat(_vm.row.status)
  }, [_vm._v(" " + _vm._s(_vm.row.status) + " ")])]), _c('div', {
    staticClass: "col-xxl-6 col-lg-6 col-md-6 mb-3"
  }, [_c('label', [_vm._v("Resident:")]), _c('p', [_vm._v(_vm._s(_vm.row.license_resident ? "Yes" : "No"))])]), _c('div', {
    staticClass: "clearfix"
  }), _vm.row.license_doc ? _c('div', {
    staticClass: "col-xxl-6 col-lg-6 col-md-6 mb-3"
  }, [_c('label', [_vm._v("License File:")]), _vm.fileType == 'pdf' ? _c('p', [_c('a', {
    attrs: {
      "href": _vm.row.license_doc,
      "target": "_blank",
      "download": ""
    }
  }, [_c('i', {
    staticClass: "fas fa-file-pdf"
  })])]) : _vm.fileType == 'image' ? _c('p', [_c('a', {
    attrs: {
      "href": _vm.row.license_doc,
      "target": "_blank",
      "download": ""
    }
  }, [_c('img', {
    staticClass: "license-doc",
    attrs: {
      "src": _vm.row.license_doc,
      "alt": _vm.row.license_id
    }
  })])]) : _vm._e()]) : _vm._e()])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }