<template>
  <div class="page-wrapper license-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>Licenses</h1>
        <custom-button
          title="Add License"
          iconClass="fas fa-plus"
          @click.native="openAddLicenseModal"
          className="add-btn"
          haveText
        />
      </div>
      <div class="row">
        <div class="col-12">
          <license-filter
            :filters="filters"
            @onSearch="searchHandler"
            @onClearSearch="clearSearchHandler"
          />
          <div class="license-card">
            <div
              class="d-flex justify-content-between align-items-center top-pagination"
            >
              <page-result :rows.sync="rows" />
              <pagination :rows.sync="rows" :filters="filters" />
            </div>
            <license-list-table :rows.sync="rows" @onSuccess="getAll" />
            <div class="d-flex justify-content-between align-items-center">
              <page-result :rows.sync="rows" />
              <pagination :rows.sync="rows" :filters="filters" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <lisense-add @onSuccess="getAll" />
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import LisenseAdd from "./LisenseAdd.vue";
import LicenseListTable from "./partials/LicenseListTable.vue";
import LicenseFilter from "./partials/LicenseFilter.vue";
const filterData = {
  rep_name: "",
  license_number: "",
  license_for: "",
};
export default {
  components: { LisenseAdd, LicenseListTable, LicenseFilter },
  name: "LicenseList",
  data: () => ({
    rows: [],
    filters: { ...filterData },
    breadcrumbItems: [{ title: "Licenses", active: true }],
  }),
  methods: {
    searchHandler(filters) {
      this.getAll(filters);
    },
    clearSearchHandler() {
      this.filters = {...filterData};
      this.getAll()
    },
    openAddLicenseModal() {
      this.$bvModal.show("addLicense");
    },
    getAll(filters = []) {
      let url;
      let loader = this.$loading.show();
      let filterUrl = Helper.objToUrlParams(filters);
      if (this.$route.params.downlineRepId) {
        url = `v2/license/get?agent_id=${atob(
          this.$route.params.downlineRepId
        )}`;
        if (filterUrl) {
          url += `&${filterUrl}`;
        }
      } else {
        url = `v2/license/get`;
        if (filterUrl) {
          url += `?${filterUrl}`;
        }
      }
      Api.get(url)
        .then((res) => {
          this.rows = res.data;
        })
        .catch(() => {
          this.rows = [];
          console.log("error");
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  watch: {
    "$route.params": {
      handler() {
        this.getAll();
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style src="@/assets/css/license.css"></style>
<style scoped>
.top-pagination {
  margin-bottom: 20px;
}
</style>
