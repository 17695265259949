<template>
  <div class="license-search-card">
    <form @submit.prevent="search">
      <div class="form-group row">
        <input-text
          input-type="number"
          labelFor="license_number"
          labelName="License Number"
          :vmodel.sync="filters.license_number"
          formGroupClass="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4"
          inputClass="license-search-card-field"
        />

        <template v-if="!$route.params.downlineRepId">
          <input-text
            labelFor="rep_name"
            labelName="Rep Name"
            :vmodel.sync="filters.rep_name"
            formGroupClass="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4"
            inputClass="license-search-card-field"
          />
          <input-multi-select
            labelFor="license_for"
            labelName="License For"
            inputName="license_for"
            :vmodel.sync="filters.license_for"
            :options="license_types.map((type) => type.value)"
            :custom-label="
              (opt) => license_types.find((x) => x.value == opt).text
            "
            formGroupClass="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-4"
            placeholder="Select Type"
          />
        </template>
      </div>

      <div class="row justify-content-md-end">
        <div class="col-xxl-2 col-xl-3">
          <button type="submit" class="univ-btn">Search</button>
        </div>
        <div class="col-xxl-2 col-xl-3">
          <button class="clear-search" @click="clearSearch">
            Clear Search
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  name: "LicenseFilter",
  props: {
    filters: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    license_types: [
      {
        value: "MYSELF",
        text: "Myself",
      },
      {
        value: "DOWNLINE_REP  ",
        text: "Downline Rep",
      },
    ],
  }),
  methods:{
    search(){
      this.$emit("onSearch", this.filters);
    },
    clearSearch(){
      this.$emit("onClearSearch");
    }
  }
};
</script>
<style>
div.multiselect {
  margin-left: 0 !important;
  width: 100% !important;
}
</style>
