<template>
  <!-- Add License pop up -->
  <b-modal id="addLicense" centered size="lg" no-close-on-backdrop>
    <template #modal-header>
      <div class="addLicense-header text-center">
        <h4>Add License</h4>

        <b-button variant="close-btn" @click="closeModal">
          <i class="fas fa-times" />
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div class="addLicense-form">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form
            @submit.stop.prevent="handleSubmit(addLicense)"
            ref="addLicenseContainer"
          >
            <div class="form-group row">
              <input-select
                :rules="{ required: true }"
                labelFor="state"
                labelName="State"
                inputName="state"
                :vmodel.sync="form.state"
                :options="states"
                defaultOption="Select Your State"
                formGroupClass="col-xxl-6 col-lg-6 col-md-6 mb-3"
                inputClass="form-control"
              />

              <input-text
                :rules="{ required: true }"
                labelFor="license_number"
                labelName="License Number"
                inputName="license_number"
                :vmodel.sync="form.license_number"
                input-type="number"
                formGroupClass="col-xxl-6 col-xl-6 col-lg-6 mb-3"
              />

              <div class="col-xxl-6 col-lg-6 col-md-6 mb-3">
                <validation-provider
                  name="License Valid Until"
                  rules="required"
                  v-slot="{ errors, valid }"
                >
                  <label for="">
                    License Valid Until
                    <span class="required-indc">*</span>
                  </label>
                  <date-picker
                    :input-class="getDatePickerClass(errors[0], valid)"
                    placeholder="License Valid Until"
                    type="date"
                    value-type="format"
                    v-model="form.license_exp_date"
                    :default-value="new Date()"
                    :disabled-date="disabledBeforeToday"
                  />
                  <!-- <span class="note-text"
                    >Note: If your license has no expiration date, enter
                    01/01/2020
                  </span>
                  <br/> -->
                  <span class="invalid-date invalid-feedback">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </div>

              <input-multi-select
                :rules="{ required: true }"
                labelFor="resident"
                labelName="Resident"
                inputName="resident"
                :vmodel.sync="form.license_resident"
                :options="residents.map((type) => type.value)"
                :custom-label="
                  (opt) => residents.find((x) => x.value == opt).text
                "
                formGroupClass="col-xxl-6 col-lg-6 col-md-6 mb-3"
                placeholder="Select Option"
              />

              <input-multi-select
                :rules="{ required: true }"
                labelFor="license_for"
                labelName="License For"
                inputName="license_for"
                :vmodel.sync="form.license_for"
                :options="license_types.map((type) => type.value)"
                :custom-label="
                  (opt) => license_types.find((x) => x.value == opt).text
                "
                formGroupClass="col-xxl-6 col-lg-6 col-md-6 mb-3"
                placeholder="Select Type"
              />

              <template v-if="form.license_for == 'DOWNLINE_REP'">
                <input-multi-select
                  :rules="{ required: true }"
                  labelFor="downline_rep"
                  labelName="Downline Rep"
                  inputName="downline_rep"
                  :vmodel.sync="form.downline_rep"
                  :options="agents.map((type) => type.value)"
                  :custom-label="
                    (opt) => agents.find((x) => x.value == opt).text
                  "
                  formGroupClass="col-xxl-6 col-lg-6 col-md-6 mb-3"
                  placeholder="Select Rep"
                  @onSearchChange="searchAgent"
              /></template>

              <div class="clearfix"></div>
              <input-file
                :rules="{ mimes: mimeTypes }"
                labelFor="license_doc"
                labelName="Upload License File(PDF,JPG or PNG)"
                inputName="license_doc"
                :vmodel.sync="form.license_doc"
                :acceptMimeTypes="mimeTypes"
                formGroupClass="col-xxl-6 col-lg-6 col-md-6 mb-3"
              />
            </div>
            <div class="form-group text-center mb-3 mt-3">
              <b-button class="univ-btn" type="submit" :disabled="isCreate">
                <template v-if="isCreate"> Adding ... </template>
                <template v-else> Add </template>
              </b-button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
export default {
  name: "LicenseAdd",
  data() {
    return {
      form: {
        state: null,
        license_resident: null,
        license_doc: null,
        license_number: "",
        license_exp_date: "",
        license_for: null,
        downline_rep: null,
      },
      states: Helper.getStates(),
      residents: [
        { value: 1, text: "Yes" },
        { value: 0, text: "No" },
      ],
      agents: [],
      license_types: [
        {
          value: "MYSELF",
          text: "Myself",
        },
        {
          value: "DOWNLINE_REP",
          text: "Downline Rep",
        },
      ],
      isCreate: false,
      mimeTypes: ["application/pdf", "image/jpeg", "image/jpg", "image/png"],
    };
  },
  methods: {
    searchAgent(q) {
      if (q.length < 2) {
        return false;
      }
      this.getDownlineRep(q);
    },
    getDownlineRep(search = "") {
      let url = `v2/get-rep-downline?size=100`;
      if (search) {
        url += `&rep_name=${search}`;
      }
      Api.get(url)
        .then((res) => {
          this.agents = res.data.data.map((agent) => {
            return {
              value: agent.id,
              text: `${agent.first_name} ${agent.last_name} (${agent.agent_code})`,
            };
          });
        })
        .catch(() => {
          this.agents = [];
        });
    },
    getDatePickerClass(error, isValid) {
      let className = "";
      if (error) {
        className = "invalid-input-block";
      } else if (isValid) {
        className = "valid-input-block";
      } else {
        className = "";
      }
      return `${className} form-control`;
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    reset() {
      this.form = {
        state: null,
        license_resident: null,
        license_doc: null,
        license_number: "",
        license_exp_date: "",
      };
    },
    closeModal() {
      this.$bvModal.hide("addLicense");
      this.reset();
    },
    addLicense() {
      let url;
      this.isCreate = true;
      url = `v3/license/add`;
      let formData = new FormData();
      formData.append("file", this.form.license_doc);
      formData.append("agent_id", this.getAgentId());
      for (const [key, value] of Object.entries(this.form)) {
        if (value) {
          formData.append(key, value);
        }
      }
      if (!this.form.license_resident) {
        formData.append("license_resident", 0);
      }
      let loader = this.$loading.show({
        container: this.$refs.addLicenseContainer,
      });
      Api.post(url, formData)
        .then((res) => {
          this.$notify(res.data.message);
          this.closeModal();
          this.$emit("onSuccess");
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isCreate = false;
          setTimeout(() => {
            loader.hide();
          }, 2000);
        });
    },
    getAgentId() {
      return this.$route.params.downlineRepId
        ? atob(this.$route.params.downlineRepId)
        : "";
    },
  },
  created() {
    this.getDownlineRep();
  },
};
</script>
