<template>
  <div>
    <simple-table :columns="columns">
      <template v-slot:tableBody>
        <tbody>
          <tr v-if="!rows.records">
            <td :colspan="6" align="center">No records found.</td>
          </tr>
          <tr v-for="(row, index) in rows.records" :key="index">
            <td>{{ row.state }}</td>
            <td>{{ row.license_number }}</td>
            <td v-if="!$route.params.downlineRepId">
              {{ row.selfLicense ?  "My Self" : row.rep_name }}
            </td>
            <td>{{ row.license_exp_date }}</td>
            <td>
              <span :class="`id-status-${row.status.toLowerCase()}`">
                {{ row.status }}
              </span>
            </td>
            <td>
              {{ row.license_resident ? "Yes" : "No" }}
            </td>
            <td>
              <div class="action-icons">
                <custom-button
                  title="View Detail"
                  iconClass="fas fa-eye"
                  @click.native="viewDetail(row)"
                />
                <custom-button
                  title="Delete"
                  iconClass="fas fa-trash-alt"
                  @click.native="deleteRow(row.license_id)"
                />
                <!-- <delete-button /> -->
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </simple-table>
    <license-view :row.sync="row" @onCloseModal="row = {}" />
  </div>
</template>
<script>
import Api from "@/resource/Api";
import LicenseView from "./LicenseView";
export default {
  name: "LicenseListTable",
  components: { LicenseView },
  props: {
    rows: {
      required: true,
    },
  },
  data: () => ({
    columns: [],
    row: {},
  }),
  methods: {
    getColumns() {
      if (this.$route.params.downlineRepId) {
        this.columns = [
          { label: "State" },
          { label: "License#" },
          { label: "Valid Until" },
          { label: "Status" },
          { label: "Resident" },
          { label: "Action" },
        ];
      } else {
        this.columns = [
          { label: "State" },
          { label: "License#" },
          { label: "Name" },
          { label: "Valid Until" },
          { label: "Status" },
          { label: "Resident" },
          { label: "Action" },
        ];
      }
    },
    viewDetail(row) {
      this.row = row;
      this.$bvModal.show("viewLicense");
    },
    deleteRow(licenseId) {
      const url = `v3/license/${licenseId}/delete`;
      this.$confirm({
        title: "Delete License",
        message: `Do you want to delete this license?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.delete(url)
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.$emit("onSuccess");
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "error");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
  },
  created() {
    this.getColumns();
  },
};
</script>
